$(document).ready(function() {        

    if($('.js-open-new-window').length){
    
        $(document).on('click','.js-open-new-window',function(event){
            link = $(this).attr('href');
        
            event.preventDefault();
            window.open(link,'Share Article '+ $(this).html,'width=600,height=450,resizable,scrollbars,status');
        });
    }

    jQuery.extend({
        handleError: function( s, xhr, status, e ) {
            // If a local callback was specified, fire it
            if ( s.error )
                s.error( xhr, status, e );
            // If we have some XML response text (e.g. from an AJAX call) then log it in the console
            else if(xhr.responseText)
                console.log(xhr.responseText);
        }
    });

    /*WebFont.load({
        custom: {
            families: ['Avenir']
        }
    });*/

    /*** Slide search ***/
    if ($('.js-slide-search-toggle').length > 0) {
        $('.js-slide-search-toggle').on('click', function(event) {
            event.preventDefault();
            $('.js-slide-search').toggleClass('is-open');
        });
    }

    // Header drop down menu function
    $('.header-wrapper .menu li a').click(function(event) {
        
        // Prevent default action
        event.preventDefault();
        
        // Ensure request has not already been made, set request blocked
        if ($('.header-dropdown-wrapper').data('requestRunning')) {
            return;
        }        
        $('.header-dropdown-wrapper').data('requestRunning', true);                
        
        // Get name of selected category
        var parentname = $(this).text();
        
        // Get nonce
        var nonce = $('.header-dropdown').attr('data-nonce');

        // Send AJAX request
        $.ajax({
            type: "GET",
            dataType: "html",
            url: "/wp-admin/admin-ajax.php",
            data: { action: "header_drop_menu", parentname: parentname, nonce: nonce }
        })
        .success(function(data) {                                                           
            
            // If menu is open close first
            if ($('.header-dropdown-wrapper').hasClass('open')) {
                clearDropdownMenu(function() {
                    openDropdownMenu(data);    
                });    
            }
            else {
                openDropdownMenu(data);    
            }
                        
        })
        .fail(function(data) {            
            console.log(data);
        });        
        
    });    
    
    $('.header-dropdown-wrapper').on('click', '#header-dropdown__close-link', function(event) { 
       
        // Prevent Default Action
        event.preventDefault();
        
        // Empty the dropdown menu
        clearDropdownMenu();
        
    });
    
    function openDropdownMenu(html) {
        
        // Add HTML and set request blocker to false
        $('.header-dropdown').append(html); 
        
        $('.header-dropdown img').load(function() {
           
            $('.header-dropdown-wrapper').data('requestRunning', false);

            // Get height
            $('.header-dropdown-wrapper').addClass('open');
            $('.header-dropdown').css('height', 'auto');

            $('.header-dropdown-shadow').css('height', 'auto');
            var drawerHeight = $('.header-dropdown-shadow').height();
            $('.header-dropdown-shadow').css('height', '0px');                        
            $('.header-dropdown-shadow').animate({'height': drawerHeight}, 500);
            
        });                
        
    }
    
    function clearDropdownMenu(cb) {    
        
        // Clear HTML and hide menu
        $('.header-dropdown-shadow').animate({'height': 0}, 500, function() {
            $('.header-dropdown').empty();
            $('.header-dropdown').css('height', '0px');
            $('.header-dropdown-wrapper').removeClass('open');
            
            // If callback method provided, call
            if (cb) {
                cb();
            }
        });
                
    }
    
    // Initialise popular stories slider on home page and override styles
    if (($('.page-outer-wrapper').children('.home-wrapper').length > 0) || ($('.page-outer-wrapper').children('.category-wrapper').length > 0)) {
        
        //Set initial slider
        var blockedSlider = false;

        if ($(window).width() < 640) {
            //Work out slider width, set secondary window size equal
            var width = ($(window).width() * (56.25 / 100));
            var margin = (($(window).width() * (6.25 / 100)) / 2);
            $('.sliderItem article').css('width', width);
            $('.sliderItem article').css('margin-left', margin);
            $('.sliderItem article').css('margin-right', margin);
            $('#sliderWrapper').css('padding-left', (margin * 6));
            $('#sliderList').css('left', ('-' + (($('.sliderItem').width()) * 2)) + 'px');
            $(window).load(function() {
                var heightWindow = $('.sliderItem').css('height');
            });    
        }
        else if ($(window).width() < 1000) {
            //Work out slider width, set secondary window size equal
            var width = ($('#sliderWrapper').width() * (30 / 100));
            var margin = ($('#sliderWrapper').width() * ((5 / 100)));
            $('.sliderItem article').css('width', width);
            $('.sliderItem article').css('margin-right', margin);
            $('#sliderList').css('left', ('-' + (($('.sliderItem').width()) * 1)) + 'px');
            $(window).load(function() {
                var heightWindow = $('.sliderItem').css('height');
            });
        }
        else {
            //Work out slider width, set secondary window size equal
            var width = ($('#sliderWrapper').width() * (18 / 100));
            var margin = ($('#sliderWrapper').width() * ((2.5 / 100)));
            $('.sliderItem article').css('width', width);
            $('.sliderItem article').css('margin-right', margin);
            $('#sliderList').css('left', ('-' + (($('.sliderItem').width()) * 1)) + 'px');
            $(window).load(function() {
                var heightWindow = $('.sliderItem').css('height');
            });
        }

        //Main slide function
        function slideLoop() {
            //Work out width of current slider size
            var widthPx = $('.sliderItem').css('width');
            var width = widthPx.substring(0, widthPx.length - 2);

            //Work out current left  
            var left = $('#sliderList').css('left');
            left = left.substring(0, left.length - 2);

            //If slider needs to start removing from front and appending to end, else only execute animation
            if (left <= -(width * 2)) {

                //Set slider position
                var neg = '-' + widthPx;
                $('#sliderList').css('left', neg);

                //Remove slide from front of main slider, append to end of list
                var slide = $('#sliderList li:first');
                $('#sliderList').children('li:first').remove();
                $('#sliderList').append(slide);			

                //Animate slides 
                var move = "-=" + widthPx;
                $('#sliderList').animate({ left: move }, "slow", "swing");
            }
            else {
                //Animate slides 
                var move = "-=" + widthPx;
                $('#sliderList').animate({ left: move }, "slow", "swing");
            }
        }

        //Function to slide left
        $('#sliderRight').click(function () {

            //Only execute if slider is not already sliding
            if (blockedSlider == false) {

                //Block the slider and clear the timer
                blockedSlider = true;

                //Work out width of current slider size and position
                var widthPx = $('.sliderItem').css('width');
                var width = widthPx.substring(0, widthPx.length - 2);
                var left = $('#sliderList').css('left');
                left = left.substring(0, left.length - 2);

                //If slider needs to start removing from front and appending to end, else only execute animation
                if (left <= -(width * 2)) {

                    //Set slider position
                    var neg = '-' + widthPx;
                    $('#sliderList').css('left', neg);                    
                    
                    //Remove slide from front of main slider, append to end of list
                    var slide = $('#sliderList li:first');
                    $('#sliderList').children('li:first').remove();
                    $('#sliderList').append(slide);				

                    //Animate slides 
                    var move = "-=" + widthPx;
                    $('#sliderList').animate({ left: move }, "slow", "swing", function() {
                        blockedSlider = false;    
                    });                
                }
                else {
                    //Animate slides 
                    var move = "-=" + widthPx;
                    $('#sliderList').animate({ left: move }, "slow", "swing", function() {
                        blockedSlider = false;    
                    });				
                }
            }
        });

        //Function to slide right
        $('#sliderLeft').click(function () {
            //Only execute if slider is not already sliding
            if (blockedSlider == false) {

                //Block the slider and clear the timer
                blockedSlider = true;

                //Work out width of current slider size and position
                var widthPx = $('.sliderItem').css('width');
                var width = widthPx.substring(0, widthPx.length - 2);
                var left = $('#sliderList').css('left');
                left = left.substring(0, left.length - 2);

                //If slider needs to start removing from front and appending to end, else only execute animation
                if (left >= -(width * 1)) {

                    //Set slider position
                    var neg = '-' + (width * 2) + 'px';
                    $('#sliderList').css('left', neg);

                    //Remove slide from end of main slider, prepend to front of list
                    var slide = $('#sliderList li:last');
                    $('#sliderList').children('li:last').remove();
                    $('#sliderList').prepend(slide);											

                    //Animate slides 
                    var move = "+=" + widthPx;
                    $('#sliderList').animate({ left: move }, "slow", "swing", function() {
                        blockedSlider = false;
                    });																	
                }
                else {
                    //Animate slides 
                    var move = "+=" + widthPx;
                    $('#sliderList').animate({ left: move }, "slow", "swing", function() {
                        blockedSlider = false;
                    });
                }
            }
        });	

        //Resize the slider if user changes browser size
        $(window).resize(function() {
                //Block the slider
                blockedSlider = true;
                
                if ($(window).width() <= 640) {
                    
                    //Work out slider width, set secondary window size equal
                    var width = ($(window).width() * (56.25 / 100));
                    var margin = (($(window).width() * (6.25 / 100)) / 2);
                    $('.sliderItem article').css('width', width);
                    $('.sliderItem article').css('margin-left', margin);
                    $('.sliderItem article').css('margin-right', margin);
                    $('#sliderWrapper').css('padding-left', (margin * 6));
                    $('#sliderList').css('left', ('-' + (($('.sliderItem').width()) * 3)) + 'px');
                }                

                //Continue sliding loop
                blockedSlider = false;
        });
                
    }
    
    // Home Page
    if ($('#page-outer-wrapper').children('.home-wrapper').length > 0) {
        
        // Line clamping on bottom latest articles
        if ($(window).width() < 640) {
            $('.latest-main__content-wrapper p').each(function(index, element) {
                $clamp(element, { clamp: 4}); 
            });
        }
        else {
            $('.latest-main__content-wrapper p').each(function(index, element) {
                $clamp(element, { clamp: 2}); 
            });
            $('.latest-main__excerpt-wrapper p').each(function(index, element) {
                $clamp(element, { clamp: 2}); 
            });
        }
    }
    
    // Single Article Page
    if ($('#page-outer-wrapper').children('#single-wrapper').length > 0) {
    
        // Social Sharing Buttons
        /*$('.single-post__social-wrapper div').sharrre({
            share: {
                twitter: true,
                facebook: true
            },
            template: '<a href="#" class="single-post__social-link single-post__social-link--facebook" id="single-post__social-link--facebook"></a><a href="#" class="single-post__social-link single-post__social-link--twitter" id="single-post__social-link--twitter"></a>',
            enableHover: false,
            enableTracking: true,
            render: function(api, options) {
              $(api.element).on('click', '#single-post__social-link--twitter', function(event) {
                 event.preventDefault();    
                 api.openPopup('twitter');
              });                
              $(api.element).on('click', '#single-post__social-link--facebook', function(event) {
                 event.preventDefault();
                 api.openPopup('facebook');
              });
            }
        });*/          
        
        // Function to change gallery descriptions         
        function changeDescription() {
        
            var activeindex = $('.single-post__gallery-thumbnail-link.active').attr('data-slide-index');
            
            // Remove current active classes
            $('.single-post__gallery-description').removeClass('active');
            
            // Add active class to selected image's description
            $('.single-post__gallery-description').each(function() {
               
                if ($(this).attr('data-slide-index') == activeindex) {
                    $(this).addClass('active');    
                }
                
            });
        }
        
        $('.single-post__gallery-thumbnails-wrapper').on('click', '#sliderLeft, #sliderRight, .single-post__gallery-thumbnail-link', changeDescription);
        
        // Function open gallery
        function openGallery(event) {
        
            // Prevent Default Action
            event.preventDefault();                        
            
            // Toggle open class
            $('.single-post__gallery-overlay').addClass('open');
            
            // Gallery Init
            if ($('.single-post__gallery-overlay').hasClass('uninitialized')) {
            
                $('#single-post__galley-list').bxSlider({
                    pagerCustom: '#single-post__gallery-thumbnails',
                    nextSelector: '#sliderRight',
                    prevSelector: '#sliderLeft',
                    nextText: '',
                    prevText: ''
                });
                
                $('.single-post__gallery-overlay').removeClass('uninitialized');
            }
            
        }
        
        // Function close gallery
        function closeGallery(event) {
        
            // Prevent Default Action
            event.preventDefault();                        
            
            // Remove open class
            $('.single-post__gallery-overlay').removeClass('open');
            
        }
        
        $('.single-wrapper').on('click', '#single-post__gallery-link', openGallery);
        $('.single-wrapper').on('click', '#single-post__gallery-close', closeGallery);
        
        // Function to sign user out of comments
        if ($('#comments').find('#logged-in-as__remove-cookie').length > 0) {
         
            $('#logged-in-as__remove-cookie').click(function(event) {
               
                // Prevent default action
                event.preventDefault();
                
                // Get cookie hash
                var cookieHash = $(this).attr('data-attr');
                var authorName = 'comment_author_' + cookieHash;
                var authorEmail = 'comment_author_email_' + cookieHash;
                var expiryDate = 'Thu, 01 Jan 1970 00:00:00';
                
                // Delete cookie 
                Cookies.remove(authorName);
                Cookies.remove(authorEmail);
                
                // Refresh page
                location.reload();
            });
            
        }
    }
    
    // Category Page
    if ($('.page-outer-wrapper').children('.category-wrapper').length > 0) {
        
        // Line clamping on bottom latest articles
        if ($(window).width() < 640) {
            $('.latest-main__content-wrapper p').each(function(index, element) {
                $clamp(element, { clamp: 4}); 
            });
        }
        else {
            $('.latest-main__content-wrapper p').each(function(index, element) {
                $clamp(element, { clamp: 2}); 
            });
            $('.latest-main__excerpt-wrapper p').each(function(index, element) {
                $clamp(element, { clamp: 2}); 
            });
        }
        
        
        // Handler for Read More link, fetch more posts via Ajax
        $('#latest-extra__more-link').on('click', function(event) {
        
            // Prevent Default Action
            event.preventDefault();
            
            // Set loading animation
            $('.latest-extra__more-wrapper').toggleClass('loading');
            
            // Get all fetched post IDs
            var postids = new Array();
            $('.latest-main__id-span').each(function() {
                postids.push(this.innerHTML); 
            });
            
            var postjson = JSON.stringify(postids);
            
            // Get parent category 
            var parentcategory = $('.latest-main__category-span').text();
            
            // Get nonce
            var nonce = $('.latest-main__category-span').attr('data-nonce');
            
            // Send AJAX request
            $.ajax({
                type: "GET",
                dataType: "html",
                url: "/wp-admin/admin-ajax.php",
                data: { action: "category_load_more", postids: postjson, parentcategory: parentcategory, nonce: nonce }
            })
            .success(function(data) {           
                
                if (data == "loaded_all") {
                    // Display loaded all message
                    $('#latest-extra__more-link').text('Loaded All')
                    
                    // Bring button back
                    $('.latest-extra__more-wrapper').toggleClass('loading');
                }
                else {
                    // Load articles
                    $('#latest-main__list').append(data);  
                    
                    // Apply line clamping
                    if ($(window).width() < 640) {
                        $('.latest-main__content-wrapper p').each(function(index, element) {
                            $clamp(element, { clamp: 4}); 
                        });
                    }
                    else {
                        $('.latest-main__content-wrapper p').each(function(index, element) {
                            $clamp(element, { clamp: 2}); 
                        });
                    }
                    
                    // Bring button back
                    $('.latest-extra__more-wrapper').toggleClass('loading');
                }
                
            })
            .fail(function(data) {            
                console.log(data);
            });
            
        });
    }
        
});
